// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import ErrorBoundary from './ErrorBoundary'; // Importe o ErrorBoundary
import 'react-toastify/dist/ReactToastify.css'; // Importe o CSS do react-toastify

// Importações do React Query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'; // Opcional, para ferramentas de desenvolvimento

// Inicialização do react-toastify
import { ToastContainer } from 'react-toastify';

// Cria uma instância do QueryClient
const queryClient = new QueryClient();

const handleUnhandledRejection = (event) => {
  console.error('Unhandled promise rejection:', event.reason);
  event.preventDefault(); // Previne a mensagem de erro padrão do navegador
};

const handleError = (event) => {
  console.error('Global error handler:', event.message);
  event.preventDefault(); // Previne a mensagem de erro padrão do navegador
};

window.addEventListener('unhandledrejection', handleUnhandledRejection);
window.addEventListener('error', handleError);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ErrorBoundary> {/* Envolve o App com o ErrorBoundary */}
      {/* Envolve a aplicação com QueryClientProvider */}
      <QueryClientProvider client={queryClient}>
        <App />
        <ToastContainer /> {/* Adicione o ToastContainer para exibir toasts */}
        <ReactQueryDevtools initialIsOpen={false} /> {/* Opcional */}
      </QueryClientProvider>
    </ErrorBoundary>
  </React.StrictMode>
);
