// src/components/CarDisplay.js

import React, { useEffect, useState } from 'react';
import { 
  saveBuildToDB, 
  getBuildsFromDB, 
  loadBuildFromDB, 
  deleteBuildFromDB, 
  fetchNFTs, 
  fetchFilteredNFTs, 
  checkCarsInRaceBatch, // Function to check cars in batch
  checkItemInRace, // Function to check items
  checkStake // Function to check staked cars
} from '../utils/api';
import '../styles/carDisplay.css'; // Ensure this file is correctly imported

const CarDisplay = ({
  userAccount,
  selectedCars,
  setSelectedCars,
  allEquippedItems,
  setAllEquippedItems,
  setShowItemSelection,
  setNotificationMessage,
  setShowNotification,
  handleSelectCar,
  handleOpenBuildPanel,
  handleAutoSelect,
  selectedCarIndexForAttributes,
  setSelectedCarIndexForAttributes,
  showBuildPanel,
  setShowBuildPanel,
  buildName,
  setBuildName,
  savedBuilds,
  setSavedBuilds,
  checkAvailability,
  clearAllPresets
}) => {
  const [loadingBuildName, setLoadingBuildName] = useState(null);
  const [isAutoLoading, setIsAutoLoading] = useState(false);
  const [isLoadBuildLoading, setIsLoadBuildLoading] = useState(false);

  // Function to load a build with availability checks
  const loadBuild = async (buildName) => {
    if (!userAccount) {
      setNotificationMessage('User is not logged in.');
      setShowNotification(true);
      return;
    }
    setIsLoadBuildLoading(true);
    setLoadingBuildName(buildName);
    try {
      // Load the build from the backend
      const buildData = await loadBuildFromDB(userAccount, buildName);
      // console.log('Loaded build data:', buildData); // Removed log

      // Safely access buildData.build or buildData
      const build = buildData?.build || buildData;

      if (!build) {
        throw new Error('Build data is undefined.');
      }

      const cars = build.cars || [];
      const items = build.items || [];

      // Fetch user's NFTs (cars) and items
      const userNfts = await fetchNFTs(userAccount);
      const userCarAssetIds = userNfts.map(nft => nft.asset_id);

      const userItems = await fetchFilteredNFTs(userAccount);
      const userItemAssetIds = userItems.map(item => item.asset_id);

      // Filter cars based on ownership
      const ownedCars = cars.filter(car => userCarAssetIds.includes(car.asset_id));

      // Filter items based on ownership
      const ownedItems = items.map((carItems, carIndex) => {
        if (!ownedCars[carIndex]) {
          return [null, null, null];
        }
        return carItems.map(item => {
          if (item && userItemAssetIds.includes(item.asset_id)) {
            return item;
          } else {
            return null;
          }
        });
      });

      const finalOwnedItems = ownedItems.slice(0, ownedCars.length);

      // Check availability of cars
      const carIds = ownedCars.map(car => car.asset_id);
      const raceStatusesResponse = await checkCarsInRaceBatch(carIds);
      // console.log('Race statuses:', raceStatuses); // Removed log

      // Safely access raceStatuses.results
      const raceStatuses = raceStatusesResponse.results;

      // Fetch staked cars
      const stakedCars = await checkStake(userAccount); // Assuming checkStake returns array of asset_ids
      const stakedCarsSet = new Set(stakedCars);

      // Map the status of each car
      const carsWithStatus = ownedCars.map(car => {
        const raceStatus = raceStatuses.find(status => status.carId === car.asset_id);
        return {
          ...car,
          inRace: raceStatus ? raceStatus.inRace : false,
          inStaking: stakedCarsSet.has(car.asset_id)
        };
      });

      // Identify unavailable cars
      const unavailableCars = carsWithStatus.filter(car => car.inRace || car.inStaking);
      const availableCars = carsWithStatus.filter(car => !car.inRace && !car.inStaking);

      // Check availability of items
      const equippedItemsWithStatus = await Promise.all(
        finalOwnedItems.map(async (carItems) => {
          return await Promise.all(carItems.map(async (item) => {
            if (item) {
              const isInRace = await checkItemInRace(item.asset_id);
              return isInRace ? null : item;
            }
            return null;
          }));
        })
      );

      // Identify unavailable items
      const unavailableItems = [];
      const availableItems = equippedItemsWithStatus.map((carItems, carIndex) => {
        const originalItems = finalOwnedItems[carIndex];
        const updatedItems = carItems.map((item, index) => {
          if (!item && originalItems[index]) {
            unavailableItems.push(originalItems[index]);
          }
          return item;
        });
        return updatedItems;
      });

      // Update state with available cars and items
      setSelectedCars(availableCars);
      setAllEquippedItems(availableItems);

      // Update the selected car index for attributes
      if (availableCars.length === 1) {
        setSelectedCarIndexForAttributes(0);
      } else {
        setSelectedCarIndexForAttributes(null);
      }

      // Prepare notification message about exclusions
      let message = '';
      if (unavailableCars.length > 0) {
        const carNames = unavailableCars.map(car => car.template.immutable_data.name).join(', ');
        message += `The following cars were removed from the build because they are in a race or staking: ${carNames}. `;
      }

      if (unavailableItems.length > 0) {
        const itemNames = unavailableItems.map(item => item.template.immutable_data.name).join(', ');
        message += `The following items were removed from the build because they are in a race: ${itemNames}.`;
      }

      if (message) {
        setNotificationMessage(message);
        setShowNotification(true);
      } else {
        setNotificationMessage('Build loaded successfully!');
        setShowNotification(true);
      }
    } catch (error) {
      console.error('Error loading build:', error);
      setNotificationMessage('Failed to load the build.');
      setShowNotification(true);
    } finally {
      setLoadingBuildName(null);
      setIsLoadBuildLoading(false);
    }
  };

  const handleLoadBuildWithChecks = async (buildName) => {
    await loadBuild(buildName);
  };

  const handleRemoveCarSlot = (index) => {
    setAllEquippedItems((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
    setSelectedCars((prev) => {
      const updated = [...prev];
      updated.splice(index, 1);
      return updated;
    });
    if (selectedCarIndexForAttributes === index) {
      setSelectedCarIndexForAttributes(null);
    }
  };

  const handleRemoveNFT = (carIndex, itemIndex) => {
    setAllEquippedItems((prev) => {
      const updated = [...prev];
      const carItems = [...updated[carIndex]];
      carItems[itemIndex] = null;
      updated[carIndex] = carItems;
      return updated;
    });
  };

  const handleEquipNFTClick = (carIndex, itemIndex) => {
    if (!selectedCars || selectedCars.length === 0) {
      setShowItemSelection({ carIndex: 0, itemIndex: null });
      return;
    }
    setShowItemSelection({ carIndex, itemIndex: null });
  };

  const handleCarSlotClick = (carIndex) => {
    if (selectedCarIndexForAttributes !== carIndex) {
      setSelectedCarIndexForAttributes(carIndex);
    }
  };

  const canSelectMoreCars = selectedCars.length < 10;

  const handleClearAll = async () => {
    await clearAllPresets();
  };

  useEffect(() => {
    const loadBuilds = async () => {
      if (userAccount && showBuildPanel) {
        try {
          const builds = await getBuildsFromDB(userAccount);
          const buildNames = builds.map(b => b.buildName);
          setSavedBuilds(buildNames);
        } catch (error) {
          console.error('Error loading builds:', error);
        }
      }
    };
    loadBuilds();
  }, [userAccount, showBuildPanel, setSavedBuilds]);

  const handleAutoSelectWithLoading = async () => {
    setIsAutoLoading(true);
    try {
      await handleAutoSelect();
      setNotificationMessage('Auto selection completed successfully!');
      setShowNotification(true);
    } catch (error) {
      console.error('Error during auto selection:', error);
      setNotificationMessage('Auto selection failed.');
      setShowNotification(true);
    }
    setIsAutoLoading(false);
  };

  const removeBuild = async (buildName) => {
    if (!userAccount) {
      setNotificationMessage('User is not logged in.');
      setShowNotification(true);
      return;
    }
    try {
      await deleteBuildFromDB(userAccount, buildName);
      const builds = await getBuildsFromDB(userAccount);
      const buildNames = builds.map(b => b.buildName);
      setSavedBuilds(buildNames);
      setNotificationMessage('Build deleted successfully!');
      setShowNotification(true);
    } catch (error) {
      setNotificationMessage('Failed to delete the build.');
      setShowNotification(true);
    }
  };

  const renderBuildPanel = () => (
    showBuildPanel && (
      <div className="build-panel">
        <button className="close-build-panel-btn" onClick={() => setShowBuildPanel(false)}>X</button>
        <input 
          type="text" 
          placeholder="Build Name" 
          value={buildName} 
          onChange={e => { setBuildName(e.target.value); }} 
        />
        <button onClick={saveBuild}>Save Build</button>
        <div className="build-list">
          <h4>Saved Builds:</h4>
          {(!savedBuilds || savedBuilds.length === 0) ? (
            <div>No saved builds.</div>
          ) : (
            savedBuilds.map((bn) => (
              <div key={bn} className="build-list-item">
                <span>{bn}</span>
                <div>
                  <button 
                    onClick={() => handleLoadBuildWithChecks(bn)}
                    disabled={isLoadBuildLoading && loadingBuildName === bn}
                  >
                    {isLoadBuildLoading && loadingBuildName === bn ? 'Loading...' : 'Load'}
                  </button>
                  <button 
                    onClick={() => removeBuild(bn)} 
                    disabled={isLoadBuildLoading}
                  >
                    X
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    )
  );

  const saveBuild = async () => {
    if (!buildName) {
      setNotificationMessage('Please enter a name for the build.');
      setShowNotification(true);
      return;
    }
    const buildNameRegex = /^[A-Za-z0-9 ]{1,10}$/;
    if (!buildNameRegex.test(buildName)) {
      setNotificationMessage('Build name must be up to 10 characters and contain only letters, numbers, and spaces.');
      setShowNotification(true);
      return;
    }

    if (!userAccount) {
      setNotificationMessage('User is not logged in.');
      setShowNotification(true);
      return;
    }
    try {
      await saveBuildToDB(userAccount, buildName, selectedCars, allEquippedItems);
      setNotificationMessage('Build saved successfully!');
      setShowNotification(true);
      const builds = await getBuildsFromDB(userAccount);
      const buildNames = builds.map(b => b.buildName);
      setSavedBuilds(buildNames);
    } catch (error) {
      setNotificationMessage(error.message || 'Failed to save the build.');
      setShowNotification(true);
    }
  };

  const renderSingleCarMode = () => {
    const hasCar = selectedCars.length === 1;
    const selectedCar = hasCar ? selectedCars[0] : null;
    const equippedNFTs = hasCar ? (allEquippedItems[0] || [null, null, null]) : [null, null, null];
    const raceStatus = hasCar ? selectedCar.inRace || selectedCar.inStaking : null;

    return (
      <div className="car-display">
        <div className="auto-build-buttons">
          <button className="auto-button" onClick={handleAutoSelectWithLoading} disabled={isAutoLoading}>
            Auto
          </button>
          <button className="build-button" onClick={() => setShowBuildPanel(!showBuildPanel)}>
            Build
          </button>
          <button className="build-button green" onClick={() => checkAvailability()}>
            Check
          </button>
        </div>
        {renderBuildPanel()}

        <div className="car-placeholder" onClick={handleSelectCar}>
          {hasCar ? (
            <img src={`https://atomichub-ipfs.com/ipfs/${selectedCar.template.immutable_data.img2}`} alt="Selected Car" className="car-image" />
          ) : (
            <img src="../assets/carmodelselected.png" alt="Car Placeholder" className="car-image" />
          )}
          {hasCar && raceStatus && (
            <div className="race-status">In Use</div>
          )}
          {!hasCar && canSelectMoreCars && (
            <div className="select-car-overlay">Select Car</div>
          )}
          {hasCar && canSelectMoreCars && (
            <div className="select-car-overlay">Add Car</div>
          )}
        </div>

        <div className="items-area">
          <button 
            className="items-button large-text" 
            onClick={(e)=>{e.stopPropagation(); setShowItemSelection({carIndex:0,itemIndex:null})}}
            disabled={isAutoLoading || isLoadBuildLoading}
          >
            <span className="items-text">Items</span>
          </button>
          <div className="equipped-nfts">
            {[...Array(3)].map((_, index) => (
              <div key={index} className="equipped-nft">
                {equippedNFTs[index] ? (
                  <>
                    <img src={`https://atomichub-ipfs.com/ipfs/${equippedNFTs[index].data.img}`} alt="Equipped NFT" className="nft-image" />
                    <button className="remove-nft-button" onClick={(e) => { e.stopPropagation(); handleRemoveNFT(0, index); }}>X</button>
                  </>
                ) : (
                  <div className="empty-slot" onClick={(e) => { e.stopPropagation(); handleEquipNFTClick(0, index); }}>+</div>
                )}
              </div>
            ))}
          </div>
        </div>

        <button className="clear-all-button" onClick={handleClearAll} disabled={isAutoLoading || isLoadBuildLoading}>
          Clear All
        </button>

        {/* Loading Spinner Overlay */}
        {(isAutoLoading || isLoadBuildLoading) && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    );
  };

  const renderMultipleCarsMode = () => {
    return (
      <div className="car-display">
        <div className="auto-build-buttons">
          <button className="auto-button" onClick={handleAutoSelectWithLoading} disabled={isAutoLoading}>
            Auto
          </button>
          <button className="build-button" onClick={() => setShowBuildPanel(!showBuildPanel)}>
            Build
          </button>
          <button className="build-button green" onClick={() => checkAvailability()}>
            Check
          </button>
        </div>
        {renderBuildPanel()}
        <div className="multi-cars-container">
          {selectedCars.map((car, carIndex) => {
            const equippedNFTs = allEquippedItems[carIndex] || [null, null, null];
            const raceStatus = car.inRace || car.inStaking;
            return (
              <div className={`car-slot ${raceStatus ? 'disabled' : ''}`} key={car.asset_id || carIndex} onClick={() => handleCarSlotClick(carIndex)}>
                <button className="remove-car-button" onClick={(e) => { e.stopPropagation(); handleRemoveCarSlot(carIndex); }} disabled={raceStatus}>
                  X
                </button>
                <div className="car-image-slot">
                  {car && car.template && car.template.immutable_data ? (
                    <img src={`https://atomichub-ipfs.com/ipfs/${car.template.immutable_data.img2}`} alt="Car" className="car-image" />
                  ) : (
                    <img src="../assets/carmodelselected.png" alt="Car Placeholder" className="car-image" />
                  )}
                  {raceStatus && <div className="race-status">In Use</div>}
                </div>

                <div className="car-items">
                  {[...Array(3)].map((_, itemIndex) => (
                    <div key={itemIndex} className="equipped-nft">
                      {equippedNFTs[itemIndex] ? (
                        <>
                          <img src={`https://atomichub-ipfs.com/ipfs/${equippedNFTs[itemIndex].data.img}`} alt="Equipped NFT" className="nft-image" />
                          <button className="remove-nft-button" onClick={(e) => { e.stopPropagation(); handleRemoveNFT(carIndex, itemIndex); }}>X</button>
                        </>
                      ) : (
                        <div className="empty-slot" onClick={() => handleEquipNFTClick(carIndex, itemIndex)}>+</div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        {canSelectMoreCars && (
          <div style={{textAlign:'center', marginTop:'10px'}}>
            <button className="items-button large-text" onClick={handleSelectCar} disabled={isAutoLoading || isLoadBuildLoading}>
              <span className="items-text">Add Car</span>
            </button>
          </div>
        )}
        <button className="clear-all-button" onClick={handleClearAll} disabled={isAutoLoading || isLoadBuildLoading}>
          Clear All
        </button>

        {/* Loading Spinner Overlay */}
        {(isAutoLoading || isLoadBuildLoading) && (
          <div className="loading-overlay">
            <div className="spinner"></div>
          </div>
        )}
      </div>
    )};

  if (selectedCars.length === 0 || selectedCars.length === 1) {
    return renderSingleCarMode();
  } else {
    return renderMultipleCarsMode();
  }
};

export default CarDisplay;
