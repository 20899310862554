import React, { createContext, useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'
import config from '../config'
import endpoints from '../utils/endpoints'
import {jwtDecode} from 'jwt-decode' // Mantive a importação conforme solicitado

// WharfKit dependencies
import { SessionKit } from '@wharfkit/session'
import { WebRenderer } from '@wharfkit/web-renderer'
import { WalletPluginAnchor } from '@wharfkit/wallet-plugin-anchor'
import { WalletPluginCloudWallet } from '@wharfkit/wallet-plugin-cloudwallet'
import { WalletPluginWombat } from '@wharfkit/wallet-plugin-wombat'

const AuthContext = createContext()

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [userAccount, setUserAccount] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [racingCoins, setRacingCoins] = useState(0)
  const [loading, setLoading] = useState(true)

  // sessionKit = WharfKit instance
  const [sessionKit, setSessionKit] = useState(null)
  // session = user's logged session (has .transact, etc.)
  const [session, setSession] = useState(null)
  // Removido: State para rastrear se Wombat está instalado
  // const [isWombatInstalled, setIsWombatInstalled] = useState(false)

  // Removido: useEffect que verifica se Wombat está instalado
  /*
  useEffect(() => {
    const checkWombatInstalled = () => {
      if (window.wombat) {
        setIsWombatInstalled(true)
      } else {
        setIsWombatInstalled(false)
      }
    }
    checkWombatInstalled()
    window.addEventListener('wombatInstalled', checkWombatInstalled)

    return () => {
      window.removeEventListener('wombatInstalled', checkWombatInstalled)
    }
  }, [])
  */

  // Inicializar sessionKit + restaurar se houver qualquer sessão armazenada
  useEffect(() => {
    const initializeSessionKit = () => {
      const ui = new WebRenderer()
      const anchor = new WalletPluginAnchor()
      const cloudWallet = new WalletPluginCloudWallet({
        supportedChains: [endpoints.CHAIN.id],
        url: endpoints.CLOUD_WALLET.url,
        autoUrl: endpoints.CLOUD_WALLET.autoUrl,
        loginTimeout: endpoints.CLOUD_WALLET.loginTimeout
      })

      // Incluindo Wombat diretamente, sem verificar se está instalado
      const wombat = new WalletPluginWombat({
        chainId: endpoints.WOMBAT.chainId, // Usa o Chain ID específico para Wombat
        chainUrl: endpoints.WOMBAT.chainUrl, // Usa o Chain URL específico para Wombat
      });
      const walletPlugins = [anchor, cloudWallet, wombat]

      const kit = new SessionKit({
        appName: 'Pixel Races',
        chains: [
          {
            id: endpoints.CHAIN.id,
            url: endpoints.CHAIN.url
          }
        ],
        ui,
        walletPlugins
      })

      setSessionKit(kit)

      const restoreSession = async () => {
        try {
          const restored = await kit.restore()
          if (restored && restored.permissionLevel) {
            const newSession = restored.session || restored
            console.log('[AuthContext] WharfKit session restored:', newSession)
            setSession(newSession)

            const walletAddress = newSession.permissionLevel.actor.toString()

            // Log in to the backend
            const response = await fetch(`${config.apiBaseUrl}/api/auth/login`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ walletAddress }),
              credentials: 'include'
            })
            if (response.ok) {
              const data = await response.json()
              Cookies.set('authToken', data.token, { expires: 7 })
              setRacingCoins(data.racingCoins)
              const decoded = jwtDecode(data.token)
              loginUser(decoded.wallet, decoded.isAdmin, data.racingCoins, newSession)
            } else {
              console.warn('[AuthContext] Failed to log in with the restored wallet session.')
            }
          } else {
            console.log('[AuthContext] No session to restore.')
          }
        } catch (err) {
          console.error('[AuthContext] Error restoring session:', err)
        } finally {
          setLoading(false)
        }
      }

      restoreSession()
    }

    initializeSessionKit()
  }, [])

  // loginUser function
  const loginUser = (walletAddress, isAdminFlag, coins, newSession = null) => {
    setUserAccount(walletAddress)
    setIsAdmin(isAdminFlag)
    setRacingCoins(coins || 0)
    if (newSession) {
      setSession(newSession)
    }
  }

  // logoutUser function
  const logoutUser = async () => {
    setUserAccount(null)
    setIsAdmin(false)
    setRacingCoins(0)
    setSession(null)
    // Remove cookies
    Cookies.remove('authToken')
    Cookies.remove('csrfToken')
    Cookies.remove('walletAddress')

    if (sessionKit) {
      try {
        await sessionKit.logout()
        console.log('[AuthContext] SessionKit session ended.')
      } catch (error) {
        console.error('[AuthContext] Error ending session in sessionKit:', error)
      }
    }
  }

  // loginWharfKit => if user wants to log in again without reloading
  const loginWharfKit = async () => {
    if (!sessionKit) {
      console.error('[AuthContext] sessionKit is not initialized.')
      return { success: false }
    }
    try {
      const loginResult = await sessionKit.login()
      if (loginResult && loginResult.permissionLevel) {
        const newSession = loginResult.session || loginResult
        const walletAddress = newSession.permissionLevel.actor.toString()

        const response = await fetch(`${config.apiBaseUrl}/api/auth/login`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ walletAddress }),
          credentials: 'include'
        })

        if (response.ok) {
          const data = await response.json()
          Cookies.set('authToken', data.token, { expires: 7 })
          setRacingCoins(data.racingCoins)
          const decoded = jwtDecode(data.token)
          loginUser(decoded.wallet, decoded.isAdmin, data.racingCoins, newSession)
          return { success: true }
        } else {
          console.error('[AuthContext] Failed to log in to the backend with loginWharfKit.')
          return { success: false }
        }
      }
      return { success: false }
    } catch (err) {
      console.error('[AuthContext] Error in loginWharfKit:', err)
      return { success: false }
    }
  }

  const updateRacingCoins = (coins) => {
    setRacingCoins(coins)
  }

  return (
    <AuthContext.Provider
      value={{
        userAccount,
        isAdmin,
        racingCoins,
        setRacingCoins: updateRacingCoins,
        loading,
        sessionKit,
        session,
        login: loginUser,
        logout: logoutUser,
        loginWharfKit
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
