// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './pages/Layout';
import Home from './pages/Home';
import Drops from './pages/Drops';
import HomeGame from './phaser/HomeGame';
import Stake from './pages/Stake';
// import OpenPack from './pages/OpenPack';
import About from './pages/About'; // Importar o componente About
import Cassino from './pages/cassino';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Rotas com Layout (Header e Footer) */}
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/drops" element={<Drops />} />
            <Route path="/stake" element={<Stake />} />
           {/*<Route path="/open-pack" element={<OpenPack />} />*/}
            <Route path="/about" element={<About />} /> {/* Nova rota para About */}
            {/*<Route path="/cassino" element={<Cassino />} /> {/* Nova rota para Cassino */}
          </Route>

          {/* Rotas sem Layout */}
          <Route path="/homegame" element={<HomeGame />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
