// raceActions.js
import { checkCarInRace } from './api'; // Usa sua função checkCarInRace
import Cookies from 'js-cookie';
import config from '../config';

// Funções auxiliares para pegar tokens do cookie
function getAuthToken() {
  return Cookies.get('authToken');
}
function getCsrfToken() {
  return Cookies.get('csrfToken');
}

const API_BASE_URL = config.apiBaseUrl;

/**
 * (Opcional) Buscar detalhes do carro via AtomicHub
 */
async function getNftDetailsFromAtomicHub(nftId) {
  const ATOMIC_HUB_API_URL = 'https://wax.api.atomicassets.io/atomicassets/v1/assets/';
  try {
    const response = await fetch(`${ATOMIC_HUB_API_URL}${nftId}`);
    const data = await response.json();

    if (!data || !data.data) {
      throw new Error('NFT data not found');
    }

    const attributes = data.data.template.immutable_data;

    return {
      id: nftId,
      name: attributes.name,
      img: attributes.img,
      SpeedLevel: parseInt(attributes.SpeedLevel) || 0,
      AccelerationLevel: parseInt(attributes.AccelerationLevel) || 0,
      NitroLevel: parseInt(attributes.NitroLevel) || 0,
      LuckLevel: parseInt(attributes.LuckLevel) || 0,
      CarRarity: attributes.CarRarity || 'Common',
      SkinRarity: attributes.SkinRarity || 'Common'
    };
  } catch (error) {
    console.error('Error fetching NFT details from Atomic Hub:', error);
    throw new Error('Failed to fetch NFT details from Atomic Hub.');
  }
}

/**
 * Inicia a corrida, enviando car/map/equips ao backend
 * @param {object} selectedCar
 * @param {object} selectedMap
 * @param {string} userAccount
 * @param {object} carUsage (ex.: { carId: { count, lastUsed, raceTime } })
 * @param {function} setCarUsage
 * @param {function} setRaceTime
 * @param {function} setErrorMessage
 * @param {function} setRacesCompleted
 * @param {array} equippedNFTs
 */
export const startRace = async (
  selectedCar,
  selectedMap,
  userAccount,
  carUsage,
  setCarUsage,
  setRaceTime,
  setErrorMessage,
  setRacesCompleted,
  equippedNFTs
) => {
  try {
    if (!selectedCar) throw new Error('No car selected.');
    if (!selectedMap) throw new Error('No map selected.');
    if (!userAccount) throw new Error('No userAccount provided.');

    const carId = selectedCar.asset_id;
    const carTemplateId = selectedCar.template_id || selectedCar.template?.template_id;
    const carImageUrl = selectedCar.template?.immutable_data?.img2;
    const mapImageUrl = selectedMap.image;

    if (!carId) throw new Error('No carId found in selectedCar.');
    if (!carTemplateId) throw new Error('Car template ID not found.');
    if (!carImageUrl) throw new Error('Car image URL not found.');
    if (!mapImageUrl) throw new Error('Map image URL not found.');

    // Verificar se o carro já está em corrida
    const raceStatus = await checkCarInRace(carId);
    if (raceStatus.inRace) {
      throw new Error('The car is already in an ongoing race.');
    }

    // Checar uso do carro (exemplo de limite local)
    const currentTime = new Date().toISOString();
    const usageInfo = carUsage[carId] || { count: 0, lastUsed: currentTime };

    if (
      usageInfo.count >= 10 &&
      new Date().getTime() - new Date(usageInfo.lastUsed).getTime() < 12 * 60 * 60 * 1000
    ) {
      throw new Error('Usage limit for this car reached.');
    }

    // Recupera authToken e CSRF
    const authToken = getAuthToken();
    const csrfToken = getCsrfToken();
    if (!authToken || !csrfToken) {
      throw new Error('Authentication failed. Please log in again.');
    }

    // (Opcional) Buscar detalhes via AtomicHub
    let carNftDetails = null;
    try {
      carNftDetails = await getNftDetailsFromAtomicHub(carId);
    } catch (error) {
      console.warn('Could not fetch optional NFT details:', error.message);
    }

    // Montar array de itens equipados
    const itemsWithIds = equippedNFTs
  .filter((i) => i !== null)
  .map((item) => ({
    asset_id: item.asset_id,
    collectionName: item.collection?.collection_name,
    templateId: item.template?.template_id,
    victoryChance: item.victoryChance,
    dropChance: item.dropChance,
    xpBonus: item.xpBonus || 0,
    img: item.template?.immutable_data?.img || ''  // Adiciona imagem do item
  }));

    const raceData = {
      walletAddress: userAccount,
      carId,
      carTemplateId,
      carImageUrl,
      mapId: selectedMap._id,
      mapImageUrl,
      startTime: currentTime,
      duration: selectedMap.raceTime,
      carNftDetails,
      equippedItems: itemsWithIds
    };

    // Chamar rota start-race
    const response = await fetch(`${API_BASE_URL}/api/protected/start-race`, {
      method: 'POST',
      credentials: 'include', // permite enviar cookies (incl. authToken se "secure" e "sameSite" permitirem)
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
        'CSRF-Token': csrfToken
      },
      body: JSON.stringify(raceData)
    });

    if (!response.ok) {
      let errorMsg = 'Failed to start the race.';
      try {
        const errorData = await response.json();
        errorMsg = errorData.message || errorMsg;
      } catch (err2) {
        // ignore parse error
      }
      throw new Error(errorMsg);
    }

    const data = await response.json();

    // Atualiza tempo e uso
    setRaceTime(selectedMap.raceTime);
    setCarUsage({
      ...carUsage,
      [carId]: {
        count: usageInfo.count + 1,
        lastUsed: new Date().toISOString(),
        raceTime: selectedMap.raceTime
      }
    });
    if (data.racesCompleted !== undefined) {
      setRacesCompleted(data.racesCompleted);
    }

    return data;
  } catch (error) {
    console.error('Error starting the race:', error?.message || error);
    throw error;
  }
};
