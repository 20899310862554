// src/components/RewardsModal.js

import React, { useState, useEffect } from 'react'; 
import '../styles/rewardsModal.css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { collectRewards, fetchMaps, getRewards } from '../utils/api';
import seasontrophie from '../assets/seasontrophie.png';
import { TailSpin } from 'react-loader-spinner';

const RewardsLog = ({ eventsLog }) => {
  const parseEventLine = (line) => {
    const ptsPattern = /\(([-+]?\d+)\s*pts\)$/i;
    let ptsValue = null;
    let text = line;
    if (line.match(ptsPattern)) {
      ptsValue = parseInt(line.match(ptsPattern)[1], 10);
      text = line.replace(ptsPattern, '').trim();
    }
    return { text, pts: ptsValue };
  };

  const getPtsColor = (pts) => {
    if (pts > 0) return '#00ff00'; 
    if (pts < 0) return '#ff0000'; 
    return '#ffffff'; 
  };

  if (!eventsLog || eventsLog.length === 0) return null;

  // Split logs into sections
  const preRace = [];
  const raceEvents = [];
  const postRace = [];
  const finalStandingsLines = [];

  const finalStandingsIndex = eventsLog.findIndex(line => line.includes('--- Final Standings ---'));
  const startingRaceIndex = eventsLog.findIndex(line => line.includes('Starting the race...'));
  const scoreAfterIndex = eventsLog.findIndex(line => line.includes('Score after race events:'));

  // Pre-Race Setup
  if (startingRaceIndex !== -1) {
    preRace.push(...eventsLog.slice(0, startingRaceIndex + 1));
  } else {
    preRace.push(...eventsLog);
  }

  // Race Events
  if (startingRaceIndex !== -1 && scoreAfterIndex !== -1 && scoreAfterIndex > startingRaceIndex) {
    raceEvents.push(...eventsLog.slice(startingRaceIndex + 1, scoreAfterIndex));
  }

  // Post-Race Summary
  if (scoreAfterIndex !== -1) {
    if (finalStandingsIndex !== -1) {
      postRace.push(...eventsLog.slice(scoreAfterIndex, finalStandingsIndex));
    } else {
      postRace.push(...eventsLog.slice(scoreAfterIndex));
    }
  }

  // Final Standings
  if (finalStandingsIndex !== -1) {
    finalStandingsLines.push(...eventsLog.slice(finalStandingsIndex + 1));
  }

  // Extrair linhas de experiência se existirem
  const expLines = finalStandingsLines.filter(line => line.includes('Experience gained:'));
  // Remover linhas de experiência do finalStandingsLines
  const standingsLinesWithoutExp = finalStandingsLines.filter(line => !line.includes('Experience gained:'));

  // Standings Data
  const standingsData = [];
  standingsLinesWithoutExp.forEach(line => {
    const match = line.match(/^(\d+)º\s+(.*?)\:\s+(\-?\d+)\s+pts$/);
    if (match) {
      const position = parseInt(match[1], 10);
      const name = match[2].trim();
      const score = parseInt(match[3], 10);
      standingsData.push({ position, name, score });
    }
  });

  const renderSimpleList = (lines, title) => {
    if (!lines || lines.length === 0) return null;
    return (
      <div className="simple-list-section">
        <h4 className="log-section-title">{title}</h4>
        <ul className="simple-list">
          {lines.map((line, i) => (
            <li key={i} className="simple-list-item">{line}</li>
          ))}
        </ul>
      </div>
    );
  };

  const renderRaceEvents = (events) => {
    if (!events || events.length === 0) return null;
    return (
      <div className="race-events-section">
        <h4 className="log-section-title">Race Events</h4>
        <table className="events-table">
          <thead>
            <tr>
              <th className="event-text-col">Event</th>
              <th className="event-pts-col">Points</th>
            </tr>
          </thead>
          <tbody>
            {events.map((line, idx) => {
              const { text, pts } = parseEventLine(line);
              const ptsColor = pts !== null ? getPtsColor(pts) : '#ffffff';
              const ptsDisplay = pts !== null ? ((pts > 0 ? '+' : '') + pts) : '-';
              return (
                <tr key={idx} className="event-row">
                  <td className="event-text-col">{text}</td>
                  <td className="event-pts-col" style={{color: pts !== null ? ptsColor : '#ffffff'}}>
                    {ptsDisplay}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  const renderFinalStandings = (data) => {
    if (data.length === 0) return null;
    return (
      <div className="final-standings-section">
        <h4 className="log-section-title">Final Standings</h4>
        <table className="standings-table">
          <thead>
            <tr>
              <th>Pos</th>
              <th>Name</th>
              <th>Score</th>
            </tr>
          </thead>
          <tbody>
            {data.map((player, i) => (
              <tr key={i}>
                <td>{player.position}</td>
                <td>{player.name}</td>
                <td style={{color: player.score >= 0 ? '#00ff00' : '#ff0000'}}>{player.score} pts</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // Função para renderizar o resumo pós-corrida com a linha de XP reposicionada
  const renderPostRaceSummary = (lines) => {
    if (!lines || lines.length === 0) return null;
    const coinIndex = lines.findIndex(l => l.includes('Coin chosen percent:'));
    const beforeCoin = coinIndex !== -1 ? lines.slice(0, coinIndex + 1) : lines;
    const afterCoin = coinIndex !== -1 ? lines.slice(coinIndex + 1) : [];
    // Inserir linhas de experiência após "Coin chosen percent:"
    const combined = [...beforeCoin, ...expLines, ...afterCoin];
    return renderSimpleList(combined, "Post-Race Summary");
  };

  return (
    <div className="events-log-container">
      {renderSimpleList(preRace, "Pre-Race Setup")}
      {renderRaceEvents(raceEvents)}
      {renderPostRaceSummary(postRace)}
      {renderFinalStandings(standingsData)}
    </div>
  );
};

const RewardsModal = ({
  showRewards,
  setShowRewards,
  userAccount,
  setNotificationMessage,
  setShowNotification,
  fetchPXRS,
}) => {
  const [expandedRewards, setExpandedRewards] = useState({});
  const queryClient = useQueryClient();
  const itemsPerPage = 10; // Number of rewards per page
  const [currentPage, setCurrentPage] = useState(1);
  
  // Fetch rewards data
  const { data, isLoading, error, refetch } = useQuery(
    ['rewards', userAccount, currentPage],
    () => getRewards(userAccount, currentPage, itemsPerPage),
    {
      enabled: showRewards,
      keepPreviousData: true,
      staleTime: 300000, // 5 minutes
      onError: (error) => {
        console.error('Error fetching rewards:', error);
        setNotificationMessage('Error loading rewards.');
        setShowNotification(true);
      }
    }
  );

  // Fetch maps data
  const { data: maps } = useQuery(
    ['maps', userAccount],
    async () => {
      const mapsData = await fetchMaps();
      return mapsData.reduce((acc, map) => {
        acc[map._id] = map; 
        return acc;
      }, {});
    },
    {
      enabled: showRewards,
      staleTime: 300000, // 5 minutes
    }
  );

  const rewardsWithLogs = data?.rewards || [];
  const totalPages = data?.totalPages || 1;

  // Mutation to collect rewards
  const mutation = useMutation(() => collectRewards(userAccount), {
    onSuccess: (data) => {
      if (data.details) {
        const { cpu_ok, net_ok } = data.details;
        if (!cpu_ok) {
          setNotificationMessage('CPU overloaded. Please try again later.');
          setShowNotification(true);
          return;
        }
        if (!net_ok) {
          setNotificationMessage('Network overloaded. Please try again later.');
          setShowNotification(true);
          return;
        }
      }

      const coinsCollected = data.totalCoins && data.totalCoins !== "0.00";
      const failedMints = data.mintErrors ? data.mintErrors.length : 0;

      if (coinsCollected && failedMints === 0) {
        setNotificationMessage('Rewards collected successfully!');
        setShowNotification(true);
      } else if (coinsCollected || failedMints < rewardsWithLogs.length) {
        setNotificationMessage(
          'Some rewards were collected successfully, but others failed. Please try collecting the pending rewards again.'
        );
        setShowNotification(true);
      } else {
        setNotificationMessage('Error collecting rewards. Please try again.');
        setShowNotification(true);
      }

      if (fetchPXRS && typeof fetchPXRS === 'function') {
        fetchPXRS();
      }

      setShowRewards(false);
      queryClient.invalidateQueries(['rewards', userAccount]);  // Ensure data is invalidated
    },
    onError: (error) => {
      const message = error?.message || 'Error collecting rewards. Please try again.';
      setNotificationMessage(message);
      setShowNotification(true);
    }
  });

  const handleCollectRewards = () => {
    if (mutation.isLoading) return;
    if (rewardsWithLogs.length === 0) {
      // No rewards to collect
      setNotificationMessage('No rewards to collect.');
      setShowNotification(true);
      return;
    }
    mutation.mutate();
  };

  useEffect(() => {
    // Manually refetch the data after mutation
    if (showRewards) {
      refetch();
    }
  }, [showRewards, refetch]);

  const toggleExpand = (rewardId) => {
    setExpandedRewards((prev) => ({
      ...prev,
      [rewardId]: !prev[rewardId],
    }));
  };

  return (
    <>
      <div
        className="rewards-overlay"
        onClick={() => setShowRewards(false)}
      ></div>
      <div className={`rewards-modal ${showRewards ? 'show' : ''}`}>
        <button className="close-button" onClick={() => setShowRewards(false)}>
          ✖
        </button>
        <div className="rewards-header">
          <h2>REWARDS</h2>
          <div className="rewards-header-buttons">
            <button
              className="collect-all-button"
              onClick={handleCollectRewards}
              disabled={mutation.isLoading || isLoading}
            >
              {mutation.isLoading ? 'Collecting...' : 'Collect All'}
            </button>
          </div>
        </div>

        <div className="rewards-list">
          {isLoading ? (
            <div className="rewards-loading-container">
              <TailSpin
                height="100"
                width="100"
                color="#00ff00"
                ariaLabel="loading"
              />
            </div>
          ) : error ? null : rewardsWithLogs.length > 0 ? (
            rewardsWithLogs.map((reward, index) => {
              const mapDetails = maps?.[reward.mapId] || {};
              const rewardCoins = reward.coins?.$numberDecimal
                ? parseFloat(reward.coins.$numberDecimal)
                : parseFloat(reward.coins || 0);

              return (
                <div key={index} className={`reward-item ${reward.isSeasonReward ? 'ranking-reward' : 'regular-reward-item'}`}>
                  {reward.isSeasonReward ? (
                    <>
                      <div className="season-trophy-container">
                        <img src={seasontrophie} alt="Season Trophy" className="season-trophy-image" />
                        <div className="reward-ranking-season">{reward.seasonName}</div>
                      </div>
                      <div className="reward-details">
                        <div className="reward-ranking">Ranking: {reward.position}</div>
                        <div className="reward-coins-rewardmodal">PXRS: {rewardCoins.toFixed(2)}</div>
                        <div className="reward-ranking-nfts">
                          {/* Exibir todos os NFTs ganhos sem limite */}
                          <div className="reward-ranking-nft-grid">
                            {reward.nfts && reward.nfts.length > 0 ? (
                              reward.nfts.map((nft, i) => (
                                <div key={i} className="reward-ranking-nft-slot">
                                  <img
                                    src={`https://atomichub-ipfs.com/ipfs/${nft.img}`}
                                    alt={nft.name}
                                    className="reward-ranking-nft-image"
                                  />
                                  <div className="reward-ranking-nft-amount">x{nft.amount}</div>
                                </div>
                              ))
                            ) : (
                              // Exibir 6 slots vazios se não houver NFTs
                              [...Array(6)].map((_, i) => (
                                <div key={i} className="empty-ranking-nft-slot"></div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    /* Layout das recompensas normais permanece inalterado */
                    <div className="reward-columns">
                      <div className="reward-column reward-map-column">
                        <img
                          src={mapDetails.image || 'path/to/default/map-image.png'}
                          alt="Map"
                          className="reward-map-image"
                        />
                        <div className="rewardmodal-map-name">{mapDetails.name || 'Unknown Map'}</div>
                      </div>

                      <div className="reward-column reward-details-column">
                        <img
                          src={`https://atomichub-ipfs.com/ipfs/${reward.carImage}`}
                          alt="Car"
                          className="reward-car-image"
                        />
                        <div className="reward-car-name">{reward.carName}</div>
                        <div className="reward-position">Position: {reward.position}</div>
                        <div className="reward-pxrs">PXRS: {rewardCoins.toFixed(2)}</div>
                      </div>

                      <div className="reward-column reward-nfts-column">
                        <div className="reward-nft-grid">
                          {[...Array(6)].map((_, i) =>
                            reward.nfts && reward.nfts[i] ? (
                              <div key={i} className="reward-nft-slot">
                                <img
                                  src={`https://atomichub-ipfs.com/ipfs/${reward.nfts[i].img}`}
                                  alt={reward.nfts[i].name}
                                  className="reward-nft-image"
                                />
                                <div className="reward-nft-amount">x{reward.nfts[i].amount}</div>
                              </div>
                            ) : (
                              <div key={i} className="empty-nft-slot"></div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {reward.eventsLog && reward.eventsLog.length > 0 && (
                    <div className="reward-log-toggle">
                      <button onClick={() => toggleExpand(reward._id)} className="toggle-button">
                        {expandedRewards[reward._id] ? 'Logs ▲' : 'Logs ▼'}
                      </button>
                    </div>
                  )}

                  {reward.eventsLog && reward.eventsLog.length > 0 && expandedRewards[reward._id] && (
                    <div className="reward-events-log expanded">
                      <RewardsLog eventsLog={reward.eventsLog} />
                    </div>
                  )}
                </div>
              );
            })
          ) : null}
        </div>

        {/* Pagination */} 
        <div className="rewards-pagination">
          <button
            onClick={() => {
              if (currentPage > 1) {
                setCurrentPage(prev => prev - 1);
                queryClient.prefetchQuery(['rewards', userAccount, currentPage - 1], () => getRewards(userAccount, currentPage - 1, itemsPerPage));
              }
            }}
            disabled={currentPage === 1}
            aria-label="Previous Page"
          >
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button
            onClick={() => {
              if (currentPage < totalPages) {
                setCurrentPage(prev => prev + 1);
                queryClient.prefetchQuery(['rewards', userAccount, currentPage + 1], () => getRewards(userAccount, currentPage + 1, itemsPerPage));
              }
            }}
            disabled={currentPage === totalPages}
            aria-label="Next Page"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default RewardsModal;
