import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { renewAuthToken, fetchCsrfTokenAgain } from '../utils/api' 
// NOTE: "fetchCsrfTokenAgain" é a função nova que criamos no api.js 
// Se preferir, você pode fazer inline no Header

import Cookies from 'js-cookie'
import config from '../config'
import endpoints from '../utils/endpoints'
import logo from '../assets/logo.png'
import './Header.css'
import { toast } from 'react-toastify'
import {jwtDecode} from 'jwt-decode' // Mantive a importação conforme solicitado

const Header = () => {
  const {
    userAccount,
    isAdmin,
    login,
    logout,
    setRacingCoins,
    racingCoins,
    sessionKit,
    loading
  } = useAuth()

  const [csrfToken, setCsrfToken] = useState('')
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()

  // 1) Obter CSRF token assim que monta
  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(`${config.apiBaseUrl}/api/auth/csrf-token`, {
          method: 'GET',
          credentials: 'include'
        })
        if (response.ok) {
          const data = await response.json()
          setCsrfToken(data.csrfToken)
          Cookies.set('csrfToken', data.csrfToken, { expires: 1 })
        }
      } catch (error) {
        console.error('Error fetching CSRF token:', error)
      }
    }
    fetchCsrfToken()
  }, [])

  // 2) Renovar token JWT se estiver para expirar
  useEffect(() => {
    const interval = setInterval(async () => {
      const token = Cookies.get('authToken')
      if (token) {
        try {
          const decodedToken = jwtDecode(token)
          const expiryTime = decodedToken.exp * 1000
          const currentTime = Date.now()
          if (expiryTime - currentTime < 10 * 60 * 1000) {
            const newToken = await renewAuthToken()
            if (newToken) {
              Cookies.set('authToken', newToken, { expires: 7 })
              const decoded = jwtDecode(newToken)
              login(decoded.wallet, decoded.isAdmin, racingCoins)
              console.log('[Header] Auth token successfully renewed')
            } else {
              logout()
            }
          }
        } catch (error) {
          console.error('[Header] Error during token renewal:', error)
        }
      }
    }, 5 * 60 * 1000)
    return () => clearInterval(interval)
  }, [logout, login, racingCoins])

  // 3) Login manual: WharfKit + login no backend
  const handleLogin = async () => {
    if (!sessionKit) {
      toast.error('WharfKit not initialized. Please reload.')
      return
    }
    if (!csrfToken && !Cookies.get('csrfToken')) {
      toast.error('CSRF token not found. Please reload.')
      return
    }

    try {
      console.log('[Header] handleLogin => sessionKit.login()')
      const loginResult = await sessionKit.login()

      // Pegar a session
      const newSession = loginResult.session || loginResult
      if (!newSession.chain && !newSession.client) {
        console.log('chain info not found in newSession =>', newSession)
      }
      // Se quiser checar chainId, ok

      const actor = newSession.permissionLevel.actor.toString()
      console.log('[Header] loginResult ok => actor:', actor)

      // login backend
      const response = await fetch(`${config.apiBaseUrl}/api/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Usa o CSRF token obtido no useEffect
          'CSRF-Token': csrfToken || Cookies.get('csrfToken')
        },
        body: JSON.stringify({ walletAddress: actor }),
        credentials: 'include'
      })

      if (response.ok) {
        const data = await response.json()
        Cookies.set('authToken', data.token, { expires: 7 })
        setRacingCoins(data.racingCoins)
        const decoded = jwtDecode(data.token)

        // Registra no AuthContext
        login(decoded.wallet, decoded.isAdmin, data.racingCoins, newSession)

        // Agora re-obtemos um NOVO CSRF token (pode ser o mesmo ou não, 
        // mas garante que se o back end gerar outro, 
        // tenhamos o mais recente)
        const updated = await fetchCsrfTokenAgain()
        if (!updated) {
          console.warn('[Header] Could not update CSRF token after login.')
        } else {
          
        }

      } else {
        toast.error('Backend login failed.')
      }
    } catch (error) {
      console.error('[Header] Error during login:', error)
      toast.error('Unexpected error during login.')
    }
  }

  const handleLogout = async () => {
    await logout()
    navigate('/')
  }

  const handlePlayNow = () => {
    navigate('/homegame')
  }

  const renderFallingPixels = () => {
    const pixels = []
    const totalPixels = 10
    for (let i = 0; i < totalPixels; i++) {
      const style = {
        left: `${(i / totalPixels) * 110}%`,
        animationDuration: `${2 + Math.random() * 3}s`
      }
      pixels.push(<div key={i} className="pixel" style={style}></div>)
    }
    return pixels
  }

  if (loading) {
    return <div style={{ color: '#fff' }}>Loading...</div>
  }

  return (
    <header className="menu">
      <div className="logo">
        <img src={logo} alt="Pixel Races Logo" className="logo-image" />
      </div>
      <button className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
        &#9776;
      </button>
      <nav className={`nav-center ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><button onClick={() => navigate('/')}>Home</button></li>
          <li><button onClick={() => navigate('/drops')}>Drops</button></li>
          <li><button onClick={() => navigate('/stake')}>Stake</button></li>
          <li><button onClick={() => navigate('/about')}>About</button></li>
          {userAccount && (
            <>
              <li>
                <button className="play-now-button" onClick={handlePlayNow}>
                  <i className="fas fa-gamepad"></i>
                  <span>Play</span>
                </button>
              </li>
              <li>
                <button className="logout-button" onClick={handleLogout}>
                  <i className="fas fa-sign-out-alt"></i>
                  <span>Logout</span>
                </button>
              </li>
            </>
          )}
          {!userAccount && (
            <li>
              <button className="login-button" onClick={handleLogin}>
                <i className="fas fa-sign-in-alt"></i>
                <span>Login</span>
              </button>
            </li>
          )}
        </ul>
      </nav>
      {userAccount && (
        <div className="user-info-container">
          <p className="user-info">{userAccount}</p>
        </div>
      )}
      {renderFallingPixels()}
    </header>
  )
}

export default Header
