// src/components/Home.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchPublicItems } from '../utils/api';
import './Home.css';
import logo from '../assets/logo.png'; // Importando a logo

const Home = () => {
  const navigate = useNavigate();
  const [workingItems, setWorkingItems] = useState([]);

  useEffect(() => {
    const loadWorkingItems = async () => {
      try {
        const items = await fetchPublicItems();
        setWorkingItems(items);
      } catch (error) {
        console.error('Erro ao carregar as coleções públicas:', error);
        // Opcional: Exibir uma mensagem para o usuário informando sobre o erro
      }
    };
    loadWorkingItems();
  }, []);

  const ensureHttp = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };

  const handlePlayNowClick = () => {
    const playButton = document.querySelector('.play-button');
    if (playButton) {
      playButton.classList.add('start-race');

      setTimeout(() => {
        playButton.classList.remove('start-race');
        navigate('/homegame');
      }, 0);
    } else {
      console.error('Botão de Play não encontrado.');
    }
  };

  return (
    <div className="home-layout">
      <div className="home-content">
        <div className="center-column">
          <div className="home-main-content">
            {/* Envolvendo a logo e o botão em um div */}
            <div className="logo-home-container">
              <img src={logo} alt="Pixel Races Logo" className="logo-home-image" />
              <button className="play-button" onClick={handlePlayNowClick}>
                Play Now!
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="partnerships-section">
        <h3>Partners</h3>
        <div className="partnerships-grid">
          {workingItems.map((item) => (
            <div key={item._id} className="partnership-item">
              <a href={ensureHttp(item.partnerLink)} target="_blank" rel="noopener noreferrer">
                <img src={item.imageUrl} alt={item.collectionName} />
                <p>{item.collectionName}</p>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
