// src/components/adminpanel/GlobalSettings.js
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { fetchGlobalValues, updateGlobalValues } from '../../utils/api';

const GlobalSettings = () => {
  const [values, setValues] = useState({
    raceLimit: 0,
    limitInterval: 0,
    maxBonusPerDay: 50,
    baseCost: 10,
    costIncrement: 2,
    contractName: 'racingwaxca2',
    tokenSymbol: 'PXRS',
    tokenDecimals: 8
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      try {
        const token = Cookies.get('authToken');
        const data = await fetchGlobalValues(token);
        // data has many fields, but let's just use the ones we want to show
        setValues({
          raceLimit: data.raceLimit ?? 0,
          limitInterval: data.limitInterval ?? 0,
          maxBonusPerDay: data.maxBonusPerDay ?? 50,
          baseCost: data.baseCost ?? 10,
          costIncrement: data.costIncrement ?? 2,
          contractName: data.contractName ?? 'racingwaxca2',
          tokenSymbol: data.tokenSymbol ?? 'PXRS',
          tokenDecimals: data.tokenDecimals ?? 8
        });
        setLoading(false);
      } catch (err) {
        console.error('Failed to load global values:', err);
        setLoading(false);
      }
    };
    loadData();
  }, []);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setValues(prev => ({
      ...prev,
      [name]: type === 'number' ? Number(value) : value
    }));
  };

  const handleUpdate = async () => {
    try {
      const token = Cookies.get('authToken');
      // Only send the fields we want:
      const body = {
        raceLimit: values.raceLimit,
        limitInterval: values.limitInterval,
        maxBonusPerDay: values.maxBonusPerDay,
        baseCost: values.baseCost,
        costIncrement: values.costIncrement,
        contractName: values.contractName,
        tokenSymbol: values.tokenSymbol,
        tokenDecimals: values.tokenDecimals
      };
      await updateGlobalValues(body, token);
      alert('GlobalValues updated successfully!');
    } catch (error) {
      console.error('Error updating global values:', error);
      alert('Failed to update global values');
    }
  };

  if (loading) {
    return <div>Loading Global Settings...</div>;
  }

  return (
    <div style={{ padding: '1rem' }}>
      <h2>Global Settings (Basic & Store)</h2>
      <div>
        <label>raceLimit: </label>
        <input
          type="number"
          name="raceLimit"
          value={values.raceLimit}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>limitInterval: </label>
        <input
          type="number"
          name="limitInterval"
          value={values.limitInterval}
          onChange={handleChange}
        />
      </div>

      <hr />
      <h3>Extra Store Fields</h3>
      <div>
        <label>maxBonusPerDay: </label>
        <input
          type="number"
          name="maxBonusPerDay"
          value={values.maxBonusPerDay}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>baseCost: </label>
        <input
          type="number"
          name="baseCost"
          value={values.baseCost}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>costIncrement: </label>
        <input
          type="number"
          name="costIncrement"
          value={values.costIncrement}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>contractName: </label>
        <input
          type="text"
          name="contractName"
          value={values.contractName}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>tokenSymbol: </label>
        <input
          type="text"
          name="tokenSymbol"
          value={values.tokenSymbol}
          onChange={handleChange}
        />
      </div>
      <div>
        <label>tokenDecimals: </label>
        <input
          type="number"
          name="tokenDecimals"
          value={values.tokenDecimals}
          onChange={handleChange}
        />
      </div>

      <hr />
      <button onClick={handleUpdate}>Update Global Values</button>
    </div>
  );
};

export default GlobalSettings;
